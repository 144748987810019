import { HeaderAndMenu } from '../../components/header-and-menu/header-and-menu.component'
import style from './home.page.module.css'
import NossaEquipeImage from '../../assets/images/INSTITUCIONAL-42.jpg'
import { Banner } from '../../components/banner/banner.component'
import { Footer } from '../../components/footer/footer.component'
import {ReactComponent as House} from '../../assets/icons/house.svg'
import {ReactComponent as Handshake} from '../../assets/icons/handshake.svg'
import {ReactComponent as Factory} from '../../assets/icons/factory.svg'
import {ReactComponent as Family} from '../../assets/icons/family.svg'
import {ReactComponent as Engineering} from '../../assets/icons/engineering.svg'
import {ReactComponent as ShieldPerson} from '../../assets/icons/shield_person.svg'

export function HomePage(){
    return <div className={style.Page}>

        <HeaderAndMenu />

        <Banner />

        <section id={'QuemSomos'} className={[style.Section, style.QuemSomos].join(' ')}>
            <div className={style.QuemSomosContainer}>
                <h2>Excelência e Confiança em Soluções Jurídicas Personalizadas</h2>
                <p>Fundado em 2016, o escritório Mares e Fonseca Sociedade de Advogados destaca-se pela expertise jurídica e pelo compromisso com a confiança e excelência no atendimento.</p>
                <p>Com uma filosofia baseada na busca por soluções jurídicas personalizadas e na participação direta dos sócios, o escritório entrega atendimento exclusivo e eficiente tanto para pessoas físicas quanto jurídicas. Esse modelo de atuação reforça a excelência técnica e constrói relações duradouras, alicerçadas na confiança mútua e no cuidado com cada caso.</p>
                {/* <h2>Uma história de trabalho e dedicação ao cliente</h2>
                <p>Nossos sócios são mestres e doutores, reconhecidos e apontados como referência no meio acadêmico e jurídico nacional</p>
                <p>Constituído em 2007, o CRON é reconhecido pela qualidade técnica dos sócios e pelo contato próximo dos clientes. O minucioso conhecimento do ordenamento jurídico e a excelência comprovada dos profissionais que integram a equipe são pilares que contribuem para a construção de uma história de credibilidade, solidez e proximidade na prestação de serviços jurídicos.</p> */}
            </div>
        </section>

        <section id={'AreasAtuacao'} className={[style.Section, style.AreasAtuacao].join(' ')}>
            <div className={style.AreasAtuacaoContainer}>
                <h2>Áreas de Atuação</h2>
                <div className={style.AreasAtuacaoContent}>
                    <div className={style.AreasAtuacaoContentRow}>
                        <a href='/areas-de-atuacao' className={style.AreasAtuacaoContentCard}>
                            <Handshake className={style.AreasAtuacaoImage} />
                            <h4>Direito Civil</h4>
                        </a>
                        <a href='/areas-de-atuacao' className={style.AreasAtuacaoContentCard}>
                            <House className={style.AreasAtuacaoImage} />
                            <h4>Direito Imobiliário</h4>
                        </a>
                        <a href='/areas-de-atuacao' className={style.AreasAtuacaoContentCard}>
                            <Factory className={style.AreasAtuacaoImage} />
                            <h4>Direito Empresarial</h4>
                        </a>
                    </div>
                    <div className={style.AreasAtuacaoContentRow}>
                        <a href='/areas-de-atuacao' className={style.AreasAtuacaoContentCard}>
                            <Family className={style.AreasAtuacaoImage} />
                            <h4>Direito de Família</h4>
                        </a>
                        <a href='/areas-de-atuacao' className={style.AreasAtuacaoContentCard}>
                            <Engineering className={style.AreasAtuacaoImage} />
                            <h4>Direito Trabalhista</h4>
                        </a>
                        <a href='/areas-de-atuacao' className={style.AreasAtuacaoContentCard}>
                            <ShieldPerson className={style.AreasAtuacaoImage} />
                            <h4>Direito Previdenciário</h4>
                        </a>
                    </div>
                </div>
            </div>
        </section>

        <section id={'NossaEquipe'} className={[style.Section, style.NossaEquipeImage].join(' ')} style={{backgroundImage: `url(${NossaEquipeImage})`}}>
            <div className={style.Cover} />
            <div className={style.NossaEquipeContent}>
                <a href='/nossa-equipe'><h1>Nossa Equipe</h1></a>
                <h5>Nossa equipe é composta por advogados altamente qualificados e especializados em diversas áreas do direito. Cada membro de nossa equipe traz uma riqueza de experiência e conhecimento, garantindo que nossos clientes recebam o melhor aconselhamento jurídico possível.</h5>
                <a className={'Button'} href='/nossa-equipe'>Saiba mais</a>
            </div>
        </section>

        <Footer />

    </div>
}