import { Carousel } from "../carousel/carousel.component";
import Image1 from '../../assets/images/ESCRITORIO-4.jpg'
import Image2 from '../../assets/images/ESCRITORIO-34.jpg'
import style from './banner.component.module.css'

export function Banner(){
    const images = [
        {url: Image1},
        {url: Image2},
    ];
    return <div className={style.BannerContainer}>
    <div className={style.Cover} />
    <div className={style.Header}>
        <div className={style.HeaderContent}>
        <h1><span className={style.HeaderTitle}>COMPROMISSO COM A</span><br/> EXCELÊNCIA</h1>
        <h5>No Mares e Fonseca Sociedade de Advogados, acreditamos que o sucesso de nossos clientes é o nosso sucesso. Por isso, estamos comprometidos em oferecer um serviço de excelência, pautado pela ética profissional, pela busca contínua por conhecimento, pela inovação em nossas práticas e pelo atendimento personalizado e atencioso aos nossos clientes.</h5>
        </div>
    </div>
    <Carousel value={images} numVisible={1} numScroll={1} className="custom-carousel" circular
    autoplayInterval={3000} itemTemplate={Image} style={{width: '100%'}} showIndicators={false} showNavigators={false}/>
    </div>
}

type ImageProps = {
    url: string
}

function Image(image: ImageProps) {
    return <div
        style={{ 
            backgroundImage: `url(${image.url})`, 
            backgroundSize: 'cover', 
            backgroundPosition: 'center',
            width: '100%',
            height: '100vh'
        }}
    />
}