import { Footer } from '../../components/footer/footer.component'
import { HeaderAndMenu } from '../../components/header-and-menu/header-and-menu.component'
import style from './estrutura.page.module.css'
import Esc1 from '../../assets/images/ESCRITORIO-36.jpg'
import Esc2 from '../../assets/images/ESCRITORIO-51.jpg'
import Esc3 from '../../assets/images/ESCRITORIO-60.jpg'
import Reu1 from '../../assets/images/ESCRITORIO-4.jpg'
import Reu2 from '../../assets/images/ESCRITORIO-6.jpg'
import Pri1 from '../../assets/images/ESCRITORIO-17.jpg'
import Pri2 from '../../assets/images/ESCRITORIO-23.jpg'
import Pri3 from '../../assets/images/ESCRITORIO-75.jpg'
import Pri4 from '../../assets/images/ESCRITORIO-76.jpg'

const items = [
    {
        title: 'Recepção', 
        description: 'Ao entrar em nosso escritório, você será recebido em uma ampla e elegante recepção. Nosso ambiente é projetado para oferecer uma sensação de tranquilidade e profissionalismo, com uma equipe cordial pronta para auxiliar em todas as suas necessidades.',
        images: [ Esc1, Esc2, Esc3 ]
    },
    {
        title: 'Sala de Reunião', 
        description: 'Dispomos de sala de reunião, equipada com tecnologia de ponta para conferências e apresentações. A sala é projetada para garantir privacidade e um ambiente propício para discussões estratégicas, negociações e consultas jurídicas.',
        images: [ Reu1, Reu2 ]
    },
    {
        title: 'Salas Privativas', 
        description: 'Nossos advogados trabalham em salas individuais, proporcionando um espaço tranquilo e concentrado para a análise e preparação dos casos. Cada escritório é equipado com recursos tecnológicos avançados, facilitando o acesso a informações e a execução de tarefas com máxima eficiência.',
        images: [ Pri1, Pri2, Pri3, Pri4 ]
    },
    {
        title: 'Estacionamento e Localização', 
        description: 'Para maior conveniência, nosso escritório dispõe de estacionamento próprio para clientes e visitantes. Além disso, estamos localizados na área central da cidade, com vias de fácil acesso, garantindo que todos possam usufruir dos nossos serviços com facilidade e segurança.'
    }
]
export function EstruturaPage(){
    return <div className={style.Page}>
        <HeaderAndMenu page='Estrutura' />
        <div className={style.Container} >
            <h1 className='Underlined'>Nossa Estrutura</h1>
            <h4>Nosso escritório foi projetado para oferecer aos nossos clientes um ambiente moderno, funcional, acolhedor com a privacidade peculiar necessária. Nossa estrutura foi cuidadosamente planejada para proporcionar conforto, eficiência e um atendimento de excelência.</h4>
            <div className={style.List}>
                {items.map((item, index) => <div className={style.Item} key={index}>
                    <div className={style.TitleContainer}>
                        <div className={style.Line} />
                        <h4>{item.title}</h4>
                    </div>
                    <p>{item.description}</p>
                    <div className={style.Images}>
                        {item.images?.map((imageItem, imageIndex) => <img key={imageIndex} className={style.Image} src={imageItem} loading='lazy' />)}
                    </div>
                </div>)}
            </div>
            <p>Com essa estrutura cuidadosamente planejada, o MARES & FONSECA se compromete a oferecer um serviço de qualidade, focado em resultados e na satisfação total dos nossos clientes. Venha nos visitar e conheça de perto nossa infraestrutura. Estamos prontos para atender você com excelência e dedicação.</p>
            <a className='Button' href='/contato'>Clique e descubra nossa localização</a>
        </div>
        <Footer />
    </div>
}