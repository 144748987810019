import style from './footer.component.module.css'
import horizontalLogo from '../../assets/logos/PNG sem fundo Mares e Fonseca-05.png'

export function Footer(){
    return <footer className={style.Footer}>
        <div className={style.FooterContent}>
            <a href='/'><img className={style.LogoImage} src={horizontalLogo} alt='' /></a>
            <div className={style.Menu}>
                <a href='/'><h5>Home</h5></a>
                <a href='/quem-somos'><h5>Quem Somos</h5></a>
                <a href='/areas-atuacao'><h5>Áreas de Atuação</h5></a>
                <a href='/nossa-equipe'><h5>Nossa Equipe</h5></a>
                <a href='/estrutura'><h5>Estrutura</h5></a>
                <a href='/contato'><h5>Contato</h5></a>
            </div>
            <div className={style.Contact}>
                <div className={style.Item}>
                    <h5>Telefones:</h5>
                    <div className={style.ItemContent}>
                        <a href='tel:+5537999367777'>(37)9-9936-7777</a>
                        <a href='tel:+5537999953570'>(37)9-9995-3570</a>
                    </div>
                </div>
                <div className={style.Item}>
                    <h5>Email:</h5>
                    <div className={style.ItemContent}>
                        <a href='mailto:contato@maresefonseca.adv.br'>contato@maresefonseca.adv.br</a>
                    </div>
                </div>
            </div>
            <div className={style.Address}>
                <div className={style.Item}>
                    <h5>Endereço:</h5>
                    <div className={style.ItemContent}>
                        <a href='https://maps.app.goo.gl/39LTAmv3iv1izZuU7' target="_blank" rel="noreferrer">Rua Nova Serrana nº 185, 1º andar<br/>
                            bairro Nossa Senhora de Lourdes<br/>
                            Pará de Minas/MG <br/>
                            CEP.: 35.660-178
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </footer>
}