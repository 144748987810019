import { Drawer as MUIDrawer, Icon, IconButton } from '@mui/material'
import style from './header-and-menu.component.module.css'
import { useState } from 'react'
// import horizontalLogo from '../../assets/logos/horizontal-logo.png'
import horizontalLogo from '../../assets/logos/PNG sem fundo Mares e Fonseca-05.png'

const menus = [
    {id: 'QuemSomos', title: 'Quem Somos', link: '/quem-somos'},
    {id: 'AreasAtuacao', title: 'Areas de Atuação', link: '/areas-de-atuacao'},
    {id: 'NossaEquipe', title: 'Nossa Equipe', link: '/nossa-equipe'},
    {id: 'Estrutura', title: 'Estrutura', link: '/estrutura'},
    {id: 'Contato', title: 'Contato', link: '/contato'},
]

type HeaderAndMenuProps = {
    page?: 'QuemSomos' | 'AreasAtuacao' | 'NossaEquipe' | 'Estrutura' | 'Contato',
    color?: string
}

export function HeaderAndMenu({page, color}: HeaderAndMenuProps) {

    const [openMenu, setOpenMenu] = useState(false)

    return <header className={style.Header}>
        <Drawer
            open={openMenu}
            onClose={() => setOpenMenu(!openMenu)}
        />
        <div className={style.Left}>
            <a href='/'><img className={style.LogoImage} src={horizontalLogo} alt='' /></a>
        </div>
        {/* <div className={style.Center}>
            center
        </div> */}
        <div className={style.Right}>
            <div className={style.MenuRow}>
                {
                    menus.map(_ => <a key={_.id} href={_.link}><h5 className={page === _.id ? style.Selected : ''}>{_.title}</h5></a>)
                }
            </div>
            <div className={style.MenuButton} style={{color: color ? color : 'inherit'}}>
            <IconButton onClick={() => setOpenMenu(!openMenu)} color={'inherit'}>
                <Icon>menu</Icon>
            </IconButton>
            </div>
        </div>
    </header>
}


type DrawerProps = {
    open: boolean,
    onClose: () => void
}
function Drawer({open, onClose}: DrawerProps){
    return <MUIDrawer
        anchor='right'
        open={open}
        onClose={onClose}
    >
    <div className={style.MenuContent}>
        <div className={style.SideMenuHeader}>
            <IconButton onClick={onClose} color='inherit'>
                <Icon>close</Icon>
            </IconButton>
        </div>
        <img className={style.LogoImage} src={horizontalLogo} width={'70%'}/>

        <div className={style.MenuColumn}>
            {
                menus.map(_ =><div className={style.MenuColItem}> <a onClick={onClose} key={_.id} href={_.link}>{_.title}</a> </div>)
            }
        </div>
    </div>
    </MUIDrawer>
}