import { HeaderAndMenu } from '../../components/header-and-menu/header-and-menu.component'
import style from './areas-de-atuacao.page.module.css'
import Image from '../../assets/images/INSTITUCIONAL-55.jpg'
import { Footer } from '../../components/footer/footer.component'
import {ReactComponent as Handshake} from '../../assets/icons/handshake.svg'
import {ReactComponent as House} from '../../assets/icons/house.svg'
import {ReactComponent as Factory} from '../../assets/icons/factory.svg'
import {ReactComponent as Engineering} from '../../assets/icons/engineering.svg'
import {ReactComponent as Family} from '../../assets/icons/family.svg'
import {ReactComponent as ShieldPerson} from '../../assets/icons/shield_person.svg'

const items = [
    {icon: Handshake, title: 'Direito Civil', description: 'Assessoria e representação em casos que envolvem sucessões (inventários, testamentos, doação, usufruto, planejamento familiar e sucessório); direito das famílias (Adoção, alimentos, anulação de casamento, casamento, união estável, divórcio, guarda, alimentos); discussões contratuais, direito das obrigações e de direitos reais, desapropriações, ações coletivas e responsabilidade civil.'},
    {icon: House, title: 'Direito Imobiliário', description: 'Assessoria e representação em casos que envolvem sucessões (inventários, testamentos, doação, usufruto, planejamento familiar e sucessório); direito das famílias (Adoção, alimentos, anulação de casamento, casamento, união estável, divórcio, guarda, alimentos); discussões contratuais, direito das obrigações e de direitos reais, desapropriações, ações coletivas e responsabilidade civil.'},
    {icon: Factory, title: 'Direito Empresarial', description: 'Assessoria e representação em casos que envolvem sucessões (inventários, testamentos, doação, usufruto, planejamento familiar e sucessório); direito das famílias (Adoção, alimentos, anulação de casamento, casamento, união estável, divórcio, guarda, alimentos); discussões contratuais, direito das obrigações e de direitos reais, desapropriações, ações coletivas e responsabilidade civil.'},
    {icon: Engineering, title: 'Direito Trabalhista', description: 'Assessoria e representação em casos que envolvem sucessões (inventários, testamentos, doação, usufruto, planejamento familiar e sucessório); direito das famílias (Adoção, alimentos, anulação de casamento, casamento, união estável, divórcio, guarda, alimentos); discussões contratuais, direito das obrigações e de direitos reais, desapropriações, ações coletivas e responsabilidade civil.'},
    {icon: Family, title: 'Direito Família', description: 'Assessoria e representação em casos que envolvem sucessões (inventários, testamentos, doação, usufruto, planejamento familiar e sucessório); direito das famílias (Adoção, alimentos, anulação de casamento, casamento, união estável, divórcio, guarda, alimentos); discussões contratuais, direito das obrigações e de direitos reais, desapropriações, ações coletivas e responsabilidade civil.'},
    {icon: ShieldPerson, title: 'Direito Previdenciário', description: 'Assessoria e representação em casos que envolvem sucessões (inventários, testamentos, doação, usufruto, planejamento familiar e sucessório); direito das famílias (Adoção, alimentos, anulação de casamento, casamento, união estável, divórcio, guarda, alimentos); discussões contratuais, direito das obrigações e de direitos reais, desapropriações, ações coletivas e responsabilidade civil.'}
]

export function AreasDeAtuacaoPage(){
    return <div className={style.Page}>
        <HeaderAndMenu page='AreasAtuacao' color={'var(--color5)'}/>
        <div className={style.ImageContainer}>
            <div className={style.Image} style={{backgroundImage: `url(${Image})`}}/>
            <div className={style.Cover} />
        </div>
        <div className={style.Container} >
            <h1 className={['Underlined', style.Underlined].join(' ')}>Areas de Atuação</h1>
            <h4>No Mares e Fonseca Sociedade de Advogados, oferecemos uma ampla gama de serviços jurídicos, incluindo, mas não se limitando a:</h4>
            <div className={style.List}>
                {items.map((item, index) => <div className={style.Item} key={index}>
                    <div className={style.IconContainer}> 
                        {/* <img className={style.Icon} src={item.icon} /> */}
                        <item.icon className={style.Icon}/>
                    </div>
                    <div className={style.ItemContent} >
                        <h3>{item.title}</h3>
                        <p>{item.description}</p>
                    </div>
                </div>
                )}
            </div>
        </div>
        <Footer />
    </div>
}