import { Footer } from '../../components/footer/footer.component'
import { HeaderAndMenu } from '../../components/header-and-menu/header-and-menu.component'
import style from './contato.page.module.css'
import {ReactComponent as Telefone} from '../../assets/icons/smartphone.svg'
import {ReactComponent as Mail} from '../../assets/icons/mail.svg'
import {ReactComponent as Alarm} from '../../assets/icons/alarm.svg'
import {ReactComponent as Location} from '../../assets/icons/location.svg'

const iconWidth = '24px'

export function ContatoPage(){
    return <div className={style.Page}>
        <HeaderAndMenu page='Contato'/>
        <div className={style.Container}>
            <h1 className='Underlined'>Fale com nossa equipe</h1>
            <h5>Estamos à disposição para atender suas necessidades jurídicas com a atenção e a dedicação que você merece. Entre em contato conosco para agendar uma consulta e descobrir como podemos ajudar você ou sua empresa a alcançar seus objetivos legais.</h5>
            <div className={style.ContactsList}>
                <div className={style.ContactItem}>
                    <div className={style.ContactItemTitleContainer}>
                        <Telefone width={iconWidth}/>
                        <h4>Telefones:</h4>
                    </div>
                    <div className={style.ContactItemDescriptionContainer}>
                        <a href={'tel:+5537999367777'}>(37)9.9936-7777</a>
                        <a href={'tel:+5537999953570'}>(37)9.9995-3570</a>
                    </div>
                </div>
                <div className={style.ContactItem}>
                    <div className={style.ContactItemTitleContainer}>
                        <Mail width={iconWidth} />
                        <h4>Email:</h4>
                    </div>
                    <div className={style.ContactItemDescriptionContainer}>
                        <a href={'mailto:contato@maresefonseca.adv.br'}>contato@maresefonseca.adv.br</a>
                    </div>
                </div>
                <div className={style.ContactItem}>
                    <div className={style.ContactItemTitleContainer}>
                        <Alarm width={iconWidth} />
                        <h4>Horário de funcionamento:</h4>
                    </div>
                    <div className={style.ContactItemDescriptionContainer}>
                        <span>Segunda – Sexta 08:00 às 17:30</span>
                    </div>
                </div>
                <div className={style.ContactItem}>
                    <div className={style.ContactItemTitleContainer}>
                        <Location width={iconWidth} />
                        <h4>Endereço:</h4>
                    </div>
                    <div className={style.ContactItemDescriptionContainer}>
                        <a href={'https://maps.app.goo.gl/5CP8jBnyo43tWqNv7'} target='blank'>Rua Nova Serrana nº 185, 1º andar<br/>
                                    bairro Nossa Senhora de Lourdes<br/>
                                    Pará de Minas/MG – CEP.: 35.660-178
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div className={style.MapContainer}>
            <iframe title='Endereço' className={style.Map} src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d469.0765143253503!2d-44.6096379!3d-19.8563587!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xa73bf385abf403%3A0x3a27bbec2ec784f2!2sR.%20Nova%20Serrana%2C%20185%20-%20Nossa%20Sra.%20de%20Lourdes%2C%20Par%C3%A1%20de%20Minas%20-%20MG%2C%2035660-178!5e0!3m2!1spt-BR!2sbr!4v1727119309082!5m2!1spt-BR!2sbr" width="100%" height="450" loading="lazy"></iframe>
        </div>
        <Footer />
    </div>
}