import { Footer } from '../../components/footer/footer.component'
import { HeaderAndMenu } from '../../components/header-and-menu/header-and-menu.component'
import style from './nossa-equipe.page.module.css'
import LuthImage from '../../assets/images/INSTITUCIONAL-1.jpg'
import AlexImage from '../../assets/images/INSTITUCIONAL-21.jpg'

export function NossaEquipePage(){
    return <div className={style.Page}>
        <HeaderAndMenu page='NossaEquipe' />
        <div className={style.Container} >
            <h1 className='Underlined'>Nossa Equipe</h1>
            <h4>Nossa equipe é composta por advogados altamente qualificados e especializados em diversas áreas do direito. Cada membro de nossa equipe traz uma riqueza de experiência e conhecimento, garantindo que nossos clientes recebam o melhor aconselhamento jurídico possível.</h4>
            <div className={style.NossaEquipeItem}>
                <p><span className={style.Name} >Dr. Luth Mares:</span> O sócio DR. LUTH MARES MARCOLINO DE FREITAS é advogado militante há mais de 12 (doze) anos, pós-graduado em Direito do Trabalho e Previdenciário pela PUC/MG, pós-graduado em Compliance e Integridade Corporativa pela PUC/MG, pós graduando em Direito Imobiliário Aplicado pela Escola Paulista de Direito – EPD, membro do Instituto Brasileiro de Direito Imobiliário – IBRADIM e da Associação Mineira dos Advogados do Direito Imobiliário – AMADI, atuando especialmente na área imobiliária tanto em âmbito judicial quanto extrajudicial. Atua na análise de riscos em transações imobiliárias e na elaboração de contratos imobiliários, visando proporcionar soluções jurídicas seguras, eficientes e personalizadas para cada negociação além de atuar em âmbito contencioso, oferecendo a seus clientes assessoria completa no Direito Imobiliário, primando pela efetiva solução dos litígios de maneira a preservar o patrimônio e os interesses de seus clientes.</p>
                <img src={LuthImage} />
            </div>
            <div className={style.NossaEquipeItem}>
                <img src={AlexImage} />
                <p><span className={style.Name} >Dr. Alex Fonseca:</span> O sócio DR. ALEX SILVA FONSECA, advogado militante a mais de 12 (doze) anos, é especialista pós-graduado em Direito Empresarial e Civil pela Faculdade Damásio de Jesus de São Paulo, membro do Instituto Brasileiro de Direito Imobiliário – IBRADIM e da Associação Mineira dos Advogados do Direito Imobiliário – AMADI, com atuação notadamente nas áreas Empresarial, Obrigacional Cível, Direito do Consumidor e Direito de Família, onde realiza trabalhos específicos, exclusivos e direcionados tanto na esfera judicial como extrajudicial, tais como; planejamentos jurídicos tanto para empresas como pessoas físicas, auxílio aos sócios e as próprias sociedades em litígios societários, e ainda na recuperação de ativos financeiros, elaboração da minuta do contrato social ou trespasse/dissolução. Atua ainda com casos voltados à alimentos (pedido de concessão, revisão, execução e exoneração), casamento (regime de bens e pacto antenupcial), divórcio (judicial e extrajudicial), guarda, curatela e tutela, partilha de bens, união estável (reconhecimento e dissolução), além de fatos jurídicos  envolvendo o direto de família com o direito imobiliário e empresarial.</p>
            </div>
        </div>
        <Footer />
    </div>
}