import { Footer } from '../../components/footer/footer.component'
import { HeaderAndMenu } from '../../components/header-and-menu/header-and-menu.component'
import style from './quem-somos.page.module.css'
import Image from '../../assets/images/INSTITUCIONAL-38.jpeg'

export function QuemSomosPage(){
    return <div className={style.Page}>
        <HeaderAndMenu page='QuemSomos'/>
        <div className={style.Content}>
            <div className={style.Text}>
                <h1 className='Underlined'>Quem Somos</h1>
                <p>Fundado em 2016, o escritório Mares e Fonseca Sociedade de Advogados combina expertise jurídica com um compromisso inabalável com os clientes.</p>
                <p>Nosso escritório foi fundado com a missão de fornecer soluções jurídicas personalizadas e eficientes, atendendo as necessidades específicas de cada cliente com excelência e dedicação, uma vez que, dentre nossos principais valores, está a confiança, que permite justamente a definição de prioridades e o ajustamento de estratégias a cada demanda, sempre cumprindo o objetivo de atender com excelência.</p>
                <p>Nossa filosofia de trabalho está alicerçada na busca de respostas únicas e na participação efetiva dos sócios para a construção de uma defesa personalizada, seja para pessoa física ou jurídica. O atendimento personalizado e atencioso resulta no desenvolvimento de relações duradouras e de confiança mútua.</p>
            </div>
            <div className={style.Image}>
                <img src={Image} />
            </div>
        </div>
        <Footer />
    </div>
}